<template>
    <div class="container">
        <div class="row mb-4">
            <h2>Lista de Cursos:</h2>
            <hr>
                <ul>
                    <li v-for="item in cursos_cadastrados" :key="item.id">
                        {{item.nome_curso}}
                    </li>
                </ul>
            <div class="col-4">
                <b-button v-b-modal="'modal-novo-curso'" size="sm" variant="primary">Cadastrar novo Curso</b-button>
            </div>

            <!-- Modal Novo Curso -->
            <b-modal size="lg" id="modal-novo-curso" title="Novo Curso">
                <b-form-group label="Nome do Curso:" label-for="modal-input-novo-curso-nome">
                    <b-form-input id="'modal-input-novo-curso-nome" v-model="modal_novo_curso.nome"></b-form-input>
                </b-form-group>

                <b-form-group label="Eixo temático:" label-for="modal-input-novo-curso-eixo">
                    <b-form-input id="'modal-input-novo-curso-eixo" v-model="modal_novo_curso.eixo"></b-form-input>
                </b-form-group>

                <b-form-group label="Conteudista Responsável:" label-for="modal-input-novo-curso-conteudista_responsavel">
                    <b-form-input id="'modal-input-novo-curso-conteudista_responsavel" v-model="modal_novo_curso.conteudista_responsavel"></b-form-input>
                </b-form-group>

                <b-form-group label="Campus ofertados:" v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                        id="checkbox-group-1"
                        v-model="modal_novo_curso.campus_oferta"
                        :options="campus_uninove_radio_options"
                        :aria-describedby="ariaDescribedby"
                    ></b-form-checkbox-group>
                </b-form-group>

                <b-form-group label="Oferta EAD?" v-slot="{ ariaDescribedby }">
                    <b-form-radio v-model="modal_novo_curso.oferta_ead" :aria-describedby="ariaDescribedby" name="some-radios" value="true">Sim</b-form-radio>
                    <b-form-radio v-model="modal_novo_curso.oferta_ead" :aria-describedby="ariaDescribedby" name="some-radios" value="false">Não</b-form-radio>
                </b-form-group>

                {{modal_novo_curso}}

                <template #modal-footer>
                    <b-button size="sm" variant="primary" @click="addNewCurso()">
                        Cadastrar
                    </b-button>  
                </template> 
            </b-modal>
        </div>

        <div class="row">
            <h2>Usuários cadastrados:</h2>
            <hr>
                <ul>
                    <li v-for="item in usuarios_cadastrados" :key="item.id">
                        {{item.nome}} | {{item.cpf}} | {{item.perfil}}
                    </li>
                </ul>
            <div class="col-4">
                <b-button v-b-modal="'modal-novo-usuario'" size="sm" variant="primary">Cadastrar novo usuário</b-button>
            </div>

            <!-- Modal Novo USUÁRIO -->
            <b-modal size="lg" id="modal-novo-usuario" title="Novo Curso">
                <b-form-group label="Nome:" label-for="modal-input-novo-usuario-nome">
                    <b-form-input id="'modal-input-novo-usuario-nome" v-model="modal_novo_usuario.nome"></b-form-input>
                </b-form-group>

                <b-form-group label="PIN:" label-for="modal-input-novo-usuario-eixo">
                    <b-form-input id="'modal-input-novo-usuario-cpf" v-model="modal_novo_usuario.cpf"></b-form-input>
                </b-form-group>

                <b-form-group label="Perfil:" label-for="modal-input-novo-usuario-conteudista_responsavel">
                    <b-form-select v-model="modal_novo_usuario.perfil" :options="options_perfil"></b-form-select>
                    <!-- <b-form-input id="modal-input-novo-usuario-conteudista_responsavel" v-model="modal_novo_usuario.perfil"></b-form-input> -->
                </b-form-group>

                <b-form-group label="Cursos Permitidos" label-for="tags-component-select">
                    <!-- Prop `add-on-change` is needed to enable adding tags vie the `change` event -->
                    <b-form-tags id="tags-component-select"
                        v-model="modal_novo_usuario.cursos_permitidos"
                        size="lg"
                        class="mb-2"
                        add-on-change
                        no-outer-focus
                    >
                        <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                        <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                            <li v-for="tag in tags" :key="tag" class="list-inline-item">
                            <b-form-tag
                                @remove="removeTag(tag)"
                                :title="tag"
                                :disabled="disabled"
                                variant="info"
                            >{{ tag }}</b-form-tag>
                            </li>
                        </ul>
                        <b-form-select
                            v-bind="inputAttrs"
                            v-on="inputHandlers"
                            :disabled="disabled || cursos_opcoes_select_novo_usuario.length === 0"
                            :options="cursos_opcoes_select_novo_usuario"
                        >
                            <template #first>
                            <!-- This is required to prevent bugs with Safari -->
                            <option disabled value="">Escolha um curso</option>
                            </template>
                        </b-form-select>
                        </template>
                    </b-form-tags>
                </b-form-group>

                {{modal_novo_usuario}}
                {{id_cursos_escolhidos_novo_usuario}}

                <template #modal-footer>
                    <b-button size="sm" variant="primary" @click="addNewUser()">
                        Cadastrar
                    </b-button>  
                </template> 
            </b-modal>
        </div>

        <hr>

        <h1>Log de eventos:</h1>
            <b-table :items="dados_tabela_log" :fields="fields_log" striped responsive="sm">
                <template #cell(show_details)="row">
                    <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                    {{ row.detailsShowing ? 'Hide' : 'Show'}} Details
                    </b-button>
                </template>

                <template #row-details="row">
                    <b-card>
                    <b-row class="mb-2">
                        <b-col sm="3" class="text-sm-right"><b>Age:</b></b-col>
                        <b-col>{{ row.item.usuario }}</b-col>
                    </b-row>

                    <b-row class="mb-2">
                        <b-col sm="3" class="text-sm-right"><b>Is Active:</b></b-col>
                        <b-col>{{ row.item.isActive }}</b-col>
                    </b-row>

                    <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
                    </b-card>
                </template>
            </b-table>

        <!-- <div class="row p-3" v-for="file in log_files" :key="file.id">
            <b-alert show variant="primary">
                <strong>{{convertTimestamp(file.timestamp)}} | {{file.usuario}}</strong>
                <hr>
                Usuário: {{file.usuario}} <br>
                Evento: {{file.evento}} <br>
                Item: {{file.item_id}} <br>
                Página: {{file.pagina}} <br>
                Curso: {{file.id_curso}} <br>
                Novo Valor: {{file.novo_valor}} <br>
            </b-alert>
        </div> -->

    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/analytics";
import 'firebase/firestore';
import logFirebase from "@/views/testes/logFirebase.js";
import moment from 'moment';

// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

export default {
    components: { 
        
    },
    data() {
        return {
            cursos_cadastrados: [],
            modal_novo_curso: {
                nome: '',
                eixo: '',
                conteudista_responsavel: '',
                campus_oferta: [],
                oferta_ead: false
            },
            campus_uninove: [],
            usuarios_cadastrados: [],
            modal_novo_usuario: {
                nome: '',
                cpf: '',
                cursos_permitidos: [],
                perfil: 'conteudista',
            },
            log_files: [],
            options_perfil: [
                { value: 'conteudista', text: 'Conteudista' },
                { value: 'uninove_geral', text: 'Uninove Geral' },
                { value: 'uninove_lab', text: 'Uninove Laboratorio' },
            ],
            fields_log: ['data', 'usuario', 'acao'],
        }
    },
    computed: {
        campus_uninove_radio_options: function(){
            let array_checkboxes
            array_checkboxes = this.campus_uninove.map(x => { return { text: x.nome, value: x.id }})    
            return array_checkboxes
        },
        cursos_opcoes_select_novo_usuario() {
            let cursos_array = this.cursos_cadastrados.map(x => x.nome_curso) 
            return cursos_array.filter(opt => this.modal_novo_usuario.cursos_permitidos.indexOf(opt) === -1)
        },
        id_cursos_escolhidos_novo_usuario(){
            return this.modal_novo_usuario.cursos_permitidos.map(x => this.cursos_cadastrados.filter(
                y => y.nome_curso == x)[0].id
            )
        },
        dados_tabela_log(){
            return this.log_files.map(x => {return{isActive: false, 'data': this.convertTimestamp(x.timestamp), 'usuario': x.usuario, 'acao': x.evento }}) 
        },


    },
    methods: {

        convertTimestamp(timestamp){
            let data =  new Date(timestamp.seconds*1000)
            let day = moment(data).locale('pt-br').format('LLL')
            // {
            //     dia: data.getDate(),
            //     mes: data.getMonth()+1,
            //     ano: data.getUTCFullYear(),
            //     minuto: data.getMinutes(),
            //     hora: data.getHours()
            // }
            // return `${data.getMinutes()}:${data.getHours()} | ${data.getDate()}/${data.getMonth()+1}/${data.getUTCFullYear()}`
            return day
        },

        atualizaCursos(){
            this.cursos_cadastrados = []
            // Solicitando os cursos
            db.collection("/nadir")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let curso = doc.data()
                    curso['id'] = doc.ref.id
                    this.cursos_cadastrados.push(curso)
                });            
            })
            .catch((error) => {
                console.log("Erro ao solicitar os cursos: ", error);
            });
        },

        atualizaCampus(){
            this.campus_uninove = []
            // Solicitando os campus
            db.collection("/campus_uninove")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let campus = doc.data()
                    this.campus_uninove.push(campus)
                });            
            })
            .catch((error) => {
                console.log("Erro ao solicitar os campus: ", error);
            });
        },

        atualizaUsuarios(){
            this.usuarios_cadastrados = []
            // Solicitando os Usuários
            db.collection("/usuarios")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let usuario = doc.data()
                    this.usuarios_cadastrados.push(usuario)
                });            
            })
            .catch((error) => {
                console.log("Erro ao solicitar os usuarios: ", error);
            });
        },

        getLogFilesFirebase(){
            this.log_files = []
            // Solicitando os Usuários
            db.collection("/log_files")
            .orderBy("timestamp")
            .limit(200)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let log_file = doc.data()
                    this.log_files.push(log_file)
                });
            })
            .catch((error) => {
                console.log("Erro ao solicitar os registros de log_files: ", error);
            });
        },

        addNewCurso(){
            console.log(this.modal_novo_curso)
            let formulario_especifico= {
                    // 1
                    item_1_3: '',
                    item_1_4: '',
                    item_1_5: '',
                    item_1_5_1: '',
                    item_1_5_2: '',
                    // 2
                    item_2: '',
                    item_2_1: '',
                    item_2_2: '',
                    // 3
                    item_3: '',
                    item_3_1: '',
                    // 4
                    item_4: '',
                    item_4_1: '',
                    item_4_2: '',
                    item_4_2_1: [],
                    item_4_3: '',
                    item_4_4: '',
                    item_4_5: '',
                    item_4_6: '',
                    item_4_6_1: '',
                    item_4_6_2: '',
                    item_4_6_3: '',
                    item_4_7: '',
                    item_4_8: '',
                    item_4_9: '',
                    item_4_9_1: '',
                    item_4_9_2: '',
                    item_4_9_3: '',
                    item_4_10: '',
                    item_5: '',
                    item_5_1: '',
                    item_5_2: '',
                    item_5_3: '',
                    item_5_4: '',
                    item_5_5: '',
                    item_5_6: '',
                    item_5_7: '',
                    item_6: '',
                    item_6_1: [],
                    item_6_2: [],
                    item_6_3: '',
                    item_6_4: [],
                    item_7: '',
                    item_7_1: '',
                    item_7_2: '',
                    item_8: '',
                    item_9: '',
                    item_9_1: '',
                    item_9_2: '',
                    item_9_3: '',
                    item_10: '',
                }

                db.collection("/nadir")
                .add({
                        formulario_especifico: formulario_especifico, 
                        nome_curso: this.modal_novo_curso.nome, 
                        eixo_curso: this.modal_novo_curso.eixo,
                        conteudista: this.modal_novo_curso.conteudista_responsavel,
                        campus_oferta: this.modal_novo_curso.campus_oferta,
                        oferta_ead: this.modal_novo_curso.oferta_ead 
                    })
                .then(() => {
                    console.log(`Curso ${this.modal_novo_curso.nome} cadastrado com sucesso`);
                    this.$bvModal.hide('modal-novo-curso')
                    this.modal_novo_curso.nome = ''
                    this.modal_novo_curso.eixo = ''
                    this.modal_novo_curso.conteudista_responsavel = ''
                    this.modal_novo_curso.campus_oferta = []
                    this.modal_novo_curso.oferta_ead = false
                    this.atualizaCursos()
                })
                .catch((error) => {
                    console.error(`Erro ao cadastrar o curso ${this.modal_novo_curso.nome}`, error);
                });
        },


        addNewUser(){
                db.collection("/usuarios")
                .add({
                    nome: this.modal_novo_usuario.nome,
                    cpf: this.modal_novo_usuario.cpf,
                    cursos_permitidos: this.id_cursos_escolhidos_novo_usuario,
                    perfil: this.modal_novo_usuario.perfil
                })
                .then(() => {
                    console.log(`Usuário ${this.modal_novo_usuario.nome} Cadastrado com sucesso!`);
                    this.$bvModal.hide('modal-novo-usuario')
                    this.modal_novo_usuario.nome = ''
                    this.modal_novo_usuario.cpf = ''
                    this.modal_novo_usuario.cursos_permitidos = []
                    this.modal_novo_usuario.perfil = 'conteudista'
                    this.atualizaUsuarios()
                })
                .catch((error) => {
                    console.error(`Erro ao cadastrar o usuário: ${this.modal_novo_usuario.nome}`, error);
                });
        },

    },
    mounted() {
        this.atualizaCursos()
        this.atualizaCampus()
        this.atualizaUsuarios()
        this.getLogFilesFirebase()
        logFirebase('evento', 'novo_valor', 'admin', this.$route.params.id_firebase, this.$route.path)
    },
    beforeCreate() {
        // console.log(localStorage.perfil)
        if(localStorage.perfil !== 'uninove_geral'){
            console.log('Pagina não autorizada!!')
            this.$router.replace({path: "/"})
        }
    }
}
</script>

<style scoped>
*{
    text-align: left;
}
</style>